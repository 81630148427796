import GlobalContextActionType from './constants';
import { IGlobalContextState } from './interface.d';

const GlobalContextReducer = (
  state: IGlobalContextState,
  { type = '', payload = {} },
): IGlobalContextState => {
  switch (type) {
    case GlobalContextActionType.SET_CONTEXT:
      return { ...state, ...payload };
    case GlobalContextActionType.SET_LOGIN_DETAILS:
      return { ...state, loginDetails: payload };
    case GlobalContextActionType.SET_USER_DETAILS:
      return { ...state, userDetails: payload };
    case GlobalContextActionType.SET_TOAST_CONFIG:
      return { ...state, toastConfig: payload };
    case GlobalContextActionType.SET_LOADER_CONFIG:
      return { ...state, loaderConfig: payload };
    default:
      return state;
  }
};

export default GlobalContextReducer;
