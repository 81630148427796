import { useGlobalContext } from 'context/GlobalContext/useGlobalContext';
import styles from './Loader.module.scss';

const Loader = ({
  showOnlyLoader = false,
  styleLoaderContainer = {},
  styleOnlyLoader = {},
}: any) => {
  const { state = {} } = useGlobalContext();
  const { loaderConfig = {} } = { ...state };
  const { show = false } = { ...loaderConfig };

  return (
    <>
      <>
        {showOnlyLoader ? (
          <div
            className={styles.onlyLoaderContainer}
            style={styleLoaderContainer}
          >
            <div className={styles.onlyLoader} style={styleOnlyLoader} />
          </div>
        ) : null}
      </>
      {show ? (
        <div className={styles.loaderContainer}>
          <div className={styles.blurredBackground} />
          <div className={styles.loader} />
        </div>
      ) : null}
    </>
  );
};

export default Loader;
