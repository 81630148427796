import Loader from 'components/Loader';
import { LOCAL_STORAGE } from 'constants/appConstants';
import { APP_ROUTES } from 'constants/routeConstants';
import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getLocalStorageItem } from 'utils/localStorage';
import routes from '../../routes/routes';

const Routes = () => {
  const routeRenderer = (
    props = {},
    { isProtected = false, component: Component = undefined } = {},
  ) => {
    try {
      const { location: { pathname = '' } = {} } = { ...props };
      const { token: isAuthenticated = '' } =
        getLocalStorageItem(LOCAL_STORAGE.USER_DETAILS) || {};
      return isAuthenticated && isProtected ? (
        <Component {...props} />
      ) : isProtected || pathname === APP_ROUTES.ROOT ? (
        <Redirect to={APP_ROUTES.LOGIN} />
      ) : isAuthenticated ? (
        <Redirect to={APP_ROUTES.DASHBOARD} />
      ) : (
        <Component {...props} />
      );
    } catch (error) {
      console.log(error);
    }
  };

  const routeComponents = routes.map((route, index = 0) => (
    <Route
      key={`${route?.path}_${index}`}
      path={route?.path}
      exact
      render={(props) => routeRenderer(props, route)}
    />
  ));

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routeComponents}
        <Redirect to={APP_ROUTES.PAGE_NOT_FOUND} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
