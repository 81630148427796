import apiConfig from 'constants/apiEndpoints';
import axiosInstance from 'services/axiosInstance';

const getUserDetailsApiCall = async (payload = {}) => {
  try {
    const apiUrl = apiConfig?.getUserDetails || '';
    const response = await axiosInstance.get(apiUrl, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default getUserDetailsApiCall;
