import appConfig from 'appConfig';

const apiConfig = {
  login: `${appConfig.BASE_URL_AUTH}api/v1/auth/login`,

  register: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users`,

  forgotPassword: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/forgotPassword`,

  resetPassword: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/resetPassword`,

  getUserDetails: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users`,
  updateUserDetails: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users`,
  uploadUserProfileImage: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/upload-image`,

  vendorsList: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors`,
  updateVendorStatus: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors`,
  removeVendor: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors`,
  communityVendorsList: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/list-community-vendors`,
  communityMembersList: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/community-vendors`,
  communityMemberDetails: (memberId = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/community-vendors/${memberId}`,
  removedVendorsList: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/removed-vendors`,
  addVendor: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/addVendor/`,
  addPopularVendor: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors`,
  vendorSearch: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/vendors`,
  vendorDetails: (vendorId = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/vendors/${vendorId}`,
  vendorIntentList: (vendorId = '') =>
    `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/vendor-intent/${vendorId}`,
  communityUsersCount: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/community-users-list`,
  popularVendorsList: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/user-vendors/popular-vendors`,
  supportFormEmail: `${appConfig.BASE_URL_AUTH_DONE}api/v1/users/support-email`,
};

export default apiConfig;
