// Utility function to set an item in localStorage
export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    // Handle errors, e.g., when localStorage is disabled or storage quota is exceeded
    console.error('Error setting item in localStorage:', error);
  }
};

// Utility function to get an item from localStorage
export const getLocalStorageItem = (key) => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    // Handle errors, e.g., when localStorage is disabled
    console.error('Error getting item from localStorage:', error);
    return null;
  }
};

// Utility function to remove an item from localStorage by key
export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    // Handle errors, e.g., when localStorage is disabled
    console.error(`Error removing item '${key}' from localStorage:`, error);
  }
};

// Utility function to clear all items from localStorage
export const clearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    // Handle errors, e.g., when localStorage is disabled
    console.error('Error clearing localStorage:', error);
  }
};
