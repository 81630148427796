export const RememberMeConstants = {
  REMEMBER_ME: 'Remember me',
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
};

export const TermsAndPrivacyConstants = {
  TEXT_ONE: "By clicking the Login button, you agree to Teleperson's",
};

export const SubmitButtonConstants = {
  LOGIN: 'Log In',
  HELP: 'Help?',
};

export const REMEMBER_ME = {
  ON: 1,
  OFF: 0,
};

export const loginSuccessfullToast = (name = '') => `Welcome, ${name}!`;

export const ValidationConfig = {
  EMAIL_REGEX: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  PASSWORD_MIN_LENGTH: 6,
};

export const InputFieldNames = {
  EMAIL: 'email',
  PASSWORD: 'password',
};

export const FormFieldConstants = {
  EMAIL_ADDRESS: 'Email Address',
  EMAIL_FIELD_PLACEHOLDER: 'enter email address',
  PASSWORD: 'Password',
  PASSWORD_FIELD_PLACEHOLDER: 'enter password',
};

export const InputBasedErrorMessage = {
  EMAIL: 'Invalid Email (e.g., example@example.com)',
  PASSWORD: `Invalid Password (At least ${ValidationConfig.PASSWORD_MIN_LENGTH} characters)`,
};

const InputTitle = {
  EMAIL: 'Your Email (e.g., example@example.com)',
  PASSWORD: `Your Password (At least ${ValidationConfig.PASSWORD_MIN_LENGTH} characters)`,
};

export const FormConfig = [
  {
    type: 'email',
    displayValue: FormFieldConstants.EMAIL_ADDRESS,
    placeholder: FormFieldConstants.EMAIL_FIELD_PLACEHOLDER,
    id: InputFieldNames.EMAIL,
    name: InputFieldNames.EMAIL,
    required: true,
    title: InputTitle.EMAIL,
  },
  {
    type: 'password',
    displayValue: FormFieldConstants.PASSWORD,
    placeholder: FormFieldConstants.PASSWORD_FIELD_PLACEHOLDER,
    id: InputFieldNames.PASSWORD,
    name: InputFieldNames.PASSWORD,
    required: true,
    title: InputTitle.PASSWORD,
  },
];
