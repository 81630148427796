import { toast } from 'react-toastify';
import { TOAST_TYPE } from './constants';

interface IShowToastFunctionParams {
  type?: string;
  message: string;
}

export const showToastFunction = ({
  type = TOAST_TYPE.INFO,
  message = '',
}: IShowToastFunctionParams) => {
  toast[type](message, {
    onClose: () => console.log('Called when I close'),
  });
};
