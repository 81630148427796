import { useGlobalContext } from 'context/GlobalContext/useGlobalContext';
import { useCallback, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TOAST_TYPE } from './constants';

const Alert = () => {
  const { state = {}, hideToast } = useGlobalContext();
  const { toastConfig = {} } = { ...state };
  const {
    type = TOAST_TYPE.INFO,
    message = '',
    show = false,
    onClose = () => undefined,
  } = { ...toastConfig };

  const notify = useCallback(
    () =>
      toast[type](message, {
        onClose,
      }),
    [message, onClose, type],
  );

  useEffect(() => {
    if (show) {
      notify();
      setTimeout(() => hideToast({ show: false }), 5000);
    }
  }, [hideToast, notify, show]);

  return (
    <ToastContainer
      autoClose={5000}
      theme="light"
      position="top-center"
      draggable
      closeOnClick
      pauseOnHover
      pauseOnFocusLoss
      rtl={false}
      newestOnTop={false}
      hideProgressBar={false}
      limit={1}
    />
  );
};

export default Alert;
