const env = process.env.HOST_VARIABLE || 'production';

const devConfig = {
  BASE_URL_AUTH: 'http://apidev.teleperson.com:3001/',
  BASE_URL_AUTH_DONE: 'http://apidev.teleperson.com:3000/',
};

const prodConfig = {
  BASE_URL_AUTH: 'http://api.teleperson.com:3001/',
  BASE_URL_AUTH_DONE: 'http://api.teleperson.com:3000/',
};

const configs = {
  development: devConfig,
  production: prodConfig,
};

export default configs[env];
