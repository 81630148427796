import { LOCAL_STORAGE } from 'constants/appConstants';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import getUserDetailsApiCall from 'services/api/getUserDetailsApi';
import { getLocalStorageItem, setLocalStorageItem } from 'utils/localStorage';
import GlobalContextActionType from './constants';
import { LoginDetailsInitialState } from './initialState';
import {
  IGlobalContextState,
  IGlobalContextType,
  IGlobalProviderProps,
} from './interface';
import GlobalContextReducer from './reducer';

const initialState: IGlobalContextState = {
  loginDetails: LoginDetailsInitialState,
  userDetails: {},
  toastConfig: {},
  loaderConfig: {},
};

const initialContext: IGlobalContextType = {
  state: initialState,
  setContext: (payload = {}) => undefined,
  setAuthenticationDetails: (payload) => undefined,
  getAuthenticationDetails: () => undefined,
  setUserDetails: (payload) => undefined,
  showToast: (payload = {}) => undefined,
  hideToast: (payload = {}) => undefined,
  showLoader: (payload = {}) => undefined,
  hideLoader: (payload = {}) => undefined,
};

export const GlobalContext = createContext<IGlobalContextType | null>(
  initialContext,
);

const GlobalProvider = ({ children }: IGlobalProviderProps) => {
  const [state, dispatch] = useReducer(GlobalContextReducer, initialState);

  const { token: isAuthenticated = '' } =
    getLocalStorageItem(LOCAL_STORAGE.USER_DETAILS) || {};

  const setContext = useCallback(
    (payload = {}) =>
      dispatch({ type: GlobalContextActionType.SET_CONTEXT, payload }),
    [],
  );

  const setAuthenticationDetails = useCallback((payload = {}) => {
    setLocalStorageItem(LOCAL_STORAGE.USER_DETAILS, payload);
    dispatch({
      type: GlobalContextActionType.SET_LOGIN_DETAILS,
      payload,
    });
  }, []);

  const getAuthenticationDetails = useCallback(
    () =>
      Object.keys(state?.loginDetails).length > 0
        ? state?.loginDetails
        : getLocalStorageItem(LOCAL_STORAGE.USER_DETAILS),
    [state.loginDetails],
  );

  const setUserDetails = useCallback((payload = {}) => {
    dispatch({
      type: GlobalContextActionType.SET_USER_DETAILS,
      payload,
    });
  }, []);

  const showToast = useCallback(
    (toastConfig = {}) =>
      dispatch({
        type: GlobalContextActionType.SET_TOAST_CONFIG,
        payload: toastConfig,
      }),
    [],
  );

  const hideToast = useCallback(
    (toastConfig = {}) =>
      dispatch({
        type: GlobalContextActionType.SET_TOAST_CONFIG,
        payload: toastConfig,
      }),
    [],
  );

  const showLoader = useCallback((loaderConfig = {}) => {
    document.body.classList.add('scroll-blocked');
    return dispatch({
      type: GlobalContextActionType.SET_LOADER_CONFIG,
      payload: { show: true, ...loaderConfig },
    });
  }, []);

  const hideLoader = useCallback((loaderConfig = {}) => {
    document.body.classList.remove('scroll-blocked');
    return dispatch({
      type: GlobalContextActionType.SET_LOADER_CONFIG,
      payload: { show: false, ...loaderConfig },
    });
  }, []);

  const fetchUserDetails = useCallback(async () => {
    try {
      const { data = {} } = await getUserDetailsApiCall();
      setUserDetails(data);
    } catch (error) {
      console.log(error);
    }
  }, [setUserDetails]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserDetails();
    }
  }, [isAuthenticated]);

  const memoizedValue = useMemo(
    () => ({
      state,
      setContext,
      setAuthenticationDetails,
      getAuthenticationDetails,
      setUserDetails,
      showToast,
      hideToast,
      showLoader,
      hideLoader,
    }),
    [
      state,
      setContext,
      setAuthenticationDetails,
      getAuthenticationDetails,
      setUserDetails,
      showToast,
      hideToast,
      showLoader,
      hideLoader,
    ],
  );

  return (
    <GlobalContext.Provider value={memoizedValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
